import classNames from 'classnames';
import type { FC } from 'react';
import { Fragment, memo } from 'react';

import { useHasAds } from '../../../../graphdebate/src/utils/common';
import type { Topic } from '../../types/api';
import { TopicLd } from './TopicLd';
import { TopicListItemV3 } from './TopicListItemV3';

export interface ComputedAd {
  position: number;
  element: JSX.Element | null;
}

interface Props {
  topics: Topic[];
  computedAds?: ComputedAd[];
  withRemove?: boolean;
  withActions?: boolean;
  topBorder?: boolean;
  bottomBorder?: boolean;
  isResponsive?: boolean;
  isSidebar?: boolean;
  itemTitleClassName?: string;
  isRefreshingData?: boolean;
  shouldHandleDsaDisplay?: boolean;
}

export const TopicList: FC<Props> = memo(function TopicList(props) {
  let {
    topics,
    computedAds,
    withRemove,
    withActions,
    topBorder,
    bottomBorder,
    isResponsive = true,
    isSidebar = false,
    itemTitleClassName = '',
    shouldHandleDsaDisplay = false,
  } = props;
  const hasAds = useHasAds();
  if (!hasAds) {
    computedAds = undefined;
  }

  return (
    <div
      className={classNames(
        'TopicList divide-y divide-gray-light',
        !shouldHandleDsaDisplay && 'border border-gray-210 rounded-[5px]',
        topBorder && 'border-t',
        bottomBorder && 'border-b',
        isSidebar && 'border-l-0 border-r-0 pl-1',
        (topBorder || bottomBorder) && 'border-gray-210',
      )}
    >
      {topics.map((topic, index) => {
        const positionAds = computedAds?.filter(elt => elt?.element && elt.position === index);
        let ads = positionAds && positionAds.length > 0 ? positionAds.map(ad => ad.element) : null;
        return (
          <Fragment key={topic?.id}>
            <TopicLd topic={topic as Topic} />
            {ads}
            <TopicListItemV3
              isResponsive={isResponsive}
              isSidebar={isSidebar}
              topic={topic as Topic}
              withRemove={withRemove}
              withActions={withActions}
              itemTitleClassName={itemTitleClassName}
              shouldHandleDsaDisplay={shouldHandleDsaDisplay}
            />
          </Fragment>
        );
      })}
    </div>
  );
});
