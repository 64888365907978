export const capitalizeFirstLetterOnly = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

/**
 * Parse a string to extract the first emoji character if exists,and the remaining content.
 */
export const emojiPrefixedParser = (str?: string) => {
  if (!str) return { emoji: null, content: '' };

  const characters = Array.from(str);

  const firstChar = characters[0];
  const isFirstCharEmoji = firstChar?.match(/\p{Emoji}/u);

  if (isFirstCharEmoji) {
    const remainingContent = characters.slice(1).join('').trim();
    return { emoji: firstChar, content: remainingContent };
  }

  return { emoji: null, content: str };
};
