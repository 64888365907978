import { ApolloError, useMutation } from '@apollo/client';
import type { FormSubscription } from 'final-form';
import { FORM_ERROR } from 'final-form';
import Link from 'next/link';
import type { MutableRefObject } from 'react';
import React, { useCallback, useEffect } from 'react';
import type { FormRenderProps } from 'react-final-form';
import { Form, FormSpy, useForm, useFormState } from 'react-final-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ErrorField } from '../components/FinalForm/ErrorField';
import { FormError } from '../components/FinalForm/FormError';
import { InputField } from '../components/FinalForm/InputField';
import { SubmitButtonV3 } from '../components/FinalForm/SubmitButtonV3';
import { confirmationToast } from '../components/Toast';
import { apiUrl } from '../config';
import { useAuthProfile, usePermissions } from '../hooks/usePermissions';
import type { GraphApiMutations, User } from '../types/api';
import { composeValidations, isEmail, maxLength, minLength } from '../utils/validation';
import { UserAccountMutation } from './api/user';

const messages = defineMessages({
  myAccount: { defaultMessage: 'Mon compte', id: 'Zm1Gfe' },
  manageMyAccount: { defaultMessage: 'gérer mon compte', id: 'mC8ovg' },
  username: { defaultMessage: 'Identifiant de connexion', id: 'sUTg0J' },
  email: { defaultMessage: 'Email', id: 'sy+pv5' },
  password: { defaultMessage: 'Mot de passe', id: 'ZVILrU' },
  save: { defaultMessage: 'Enregistrer', id: '8pmblh' },
  saved: { defaultMessage: 'Les modifications ont bien été prises en compte', id: '7Ztysx' },
  resetPassword: { defaultMessage: 'Réinitialiser votre mot de passe', id: 'Uh6KkF' },
  resetPasswordEmailSent: {
    defaultMessage:
      'Un email contenant un lien de réinitialisation vient de vous être envoyé sur cette adresse. Vérifiez votre boite de réception.',
    id: 't/1hOm',
  },
  genericError: { defaultMessage: 'Une erreur est survenue', id: 'uM26tE' },
  'username already exist': { defaultMessage: "L'identifiant choisi existe déjà.", id: 'h7Q2WW' },
  'Error: Error during password verification': {
    defaultMessage: 'Erreur lors de la vérification du mot de passe.',
    id: '+dglkr',
  },
});

type AccountFormRef = MutableRefObject<FormRenderProps['form'] | undefined>;

type AccountProps = {
  tip?: { hide: () => void };
  accountFormRef: AccountFormRef;
};

const FORM_SUBSCRIPTION = {
  submitErrors: true,
  submitError: true,
  pristine: true,
  hasValidationErrors: true,
} satisfies FormSubscription;

/** To get more properties, you must subscribe above (FORM_SUBSCRIPTION) */
type FormRenderPropsSubscribed = Pick<
  FormRenderProps<AccountFormValues>,
  keyof typeof FORM_SUBSCRIPTION | 'handleSubmit'
>;

type AccountFormValues = User & { password: string };
const usernameValidation = composeValidations([minLength(4), maxLength(30)]);

export const Account: React.FC<AccountProps> = ({ tip, accountFormRef }) => {
  const { formatMessage } = useIntl();
  const { authUserId, email, isAuthDone, username, resetPassword, validateEmailUpdate } =
    usePermissions();
  const {
    profile: { language },
  } = useAuthProfile();
  const [updateUser] = useMutation<GraphApiMutations>(UserAccountMutation, {
    variables: { language },
  });
  const onSubmit = useCallback(
    async (variables: User) => {
      const hasEmailUpdate = variables.email !== email;
      try {
        const { data, errors } = await updateUser({ variables });
        const newUser = data?.editUser;
        if (errors) throw new ApolloError({ graphQLErrors: errors });
        else if (!newUser) throw new Error('No updated user.');
        confirmationToast(<FormattedMessage {...messages.saved} />);
        hasEmailUpdate && validateEmailUpdate?.();
        return tip?.hide();
      } catch (err: any) {
        return {
          [FORM_ERROR]:
            messages[err.message as keyof typeof messages] || err.message || messages.genericError,
        };
      }
    },
    [email, tip, updateUser, validateEmailUpdate],
  );

  const openResetPassword = useCallback(() => {
    resetPassword?.(email);
    tip?.hide();
  }, [email, resetPassword, tip]);

  const renderForm = useCallback(
    ({ handleSubmit, submitError, pristine, hasValidationErrors }: FormRenderPropsSubscribed) => {
      const shouldDisplayPasswordValidation = !pristine && !hasValidationErrors;

      return (
        <form className="max-w-lg popover-panel" onSubmit={handleSubmit}>
          <div className="flex items-center justify-between border border-solid border-gray-semiLight bg-gray-semiLight py-2 px-3 rounded-sm">
            <span className="font-bold text-gray uppercase text-xs font-source tracking-wide">
              <FormattedMessage {...messages.myAccount} />
            </span>

            <Link
              href={`${apiUrl}/admin/#/account`}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <div className="flex items-center">
                <FormattedMessage {...messages.manageMyAccount} />
              </div>
            </Link>
          </div>

          <div className="flex flex-col gap-2 items-center justify-center p-2">
            <div key={'username'} className="flex flex-col w-full">
              <InputField
                name={'username'}
                placeholder={formatMessage(messages['username' as keyof typeof messages])}
                validate={usernameValidation}
              />
              <ErrorField name={'username'} />
            </div>

            <div key={'email'} className="flex flex-col w-full">
              <InputField
                name={'email'}
                type="email"
                placeholder={formatMessage(messages['email' as keyof typeof messages])}
                validate={isEmail}
              />
              <ErrorField name={'email'} />
            </div>

            {shouldDisplayPasswordValidation && (
              <div key={'password'} className="mt-2 flex flex-col w-full">
                <InputField
                  name={'password'}
                  placeholder={formatMessage(messages['password' as keyof typeof messages])}
                  type={'password'}
                  className="border-primary/50"
                  autoComplete="current-password"
                />
                <ErrorField name={'password'} />
              </div>
            )}

            <FormError submitError={submitError} />

            <div className="flex space-x-8">
              <button type="button" className="hover:underline" onClick={openResetPassword}>
                <span className="font-source">
                  <FormattedMessage {...messages.resetPassword} />
                </span>
              </button>

              <AccountSubmit accountFormRef={accountFormRef} />
            </div>
          </div>
        </form>
      );
    },
    [formatMessage, openResetPassword, accountFormRef],
  );

  return authUserId && isAuthDone ? (
    <Form
      onSubmit={onSubmit}
      initialValues={{ username, email, language }}
      subscription={FORM_SUBSCRIPTION}
      render={renderForm}
    />
  ) : null;
};

const AccountSubmit = function AccountSubmit({
  accountFormRef,
}: {
  accountFormRef: AccountFormRef;
}) {
  const form = useForm();
  const { values } = useFormState();
  const hasPassword = !!values?.password;

  useEffect(() => {
    accountFormRef.current = form;
  }, [form, accountFormRef]);

  return (
    <FormSpy
      subscription={{
        submitting: true,
        modifiedSinceLastSubmit: true,
        hasValidationErrors: true,
      }}
    >
      {({ submitting, hasSubmitErrors, modifiedSinceLastSubmit, hasValidationErrors }) => (
        <SubmitButtonV3
          disabled={
            !hasPassword ||
            submitting ||
            hasValidationErrors ||
            (hasSubmitErrors && !modifiedSinceLastSubmit)
          }
          iconClassName="_md:hidden"
        >
          <FormattedMessage {...messages.save} />
        </SubmitButtonV3>
      )}
    </FormSpy>
  );
};
