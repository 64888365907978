import type { ReactNode } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { IconArrowDown } from '../../../../../front/src/components/icons/Icons';
import { Link } from '../../../../../front/src/components/Link';
import type { GraphdebateHeading } from '../../../../../front/src/types/api';
import { emojiPrefixedParser } from '../../../../../front/src/utils/strings';
import { isMobile } from '../../../../../front/src/utils/web';
import { FakeIcon, HeadingsMenuItem, MenuNbTopicsItems } from './HMChildren';

type HeadingsMenuCategoryProps = Omit<GraphdebateHeading, 'children'> & {
  icon?: ReactNode;
  tags: GraphdebateHeading['children'];
  isModalDisplay?: boolean;
  onTagClick?: () => void;
  defaultTagsLimit?: number;
};

const HMCategoryMessages = defineMessages({
  seeMore: {
    defaultMessage: 'Voir plus',
    id: 'fwbNzt',
  },
  seeLess: {
    defaultMessage: 'Voir moins',
    id: 'hBnH0h',
  },
});

const HeadingsMenuCategory = memo(function HeadingsMenuCategory({
  content,
  tags,
  icon,
  color,
  isModalDisplay,
  onTagClick,
  defaultTagsLimit = 3,
}: HeadingsMenuCategoryProps) {
  const { formatMessage } = useIntl();
  const isMobileDisplay = isMobile();

  const NB_DEFAULT_TAGS_DISPLAYED_MOBILE = defaultTagsLimit;
  const NB_DEFAULT_TAGS_DISPLAYED_DESKTOP = defaultTagsLimit;
  const NB_DEFAULT_TAGS_DISPLAYED = isMobileDisplay
    ? NB_DEFAULT_TAGS_DISPLAYED_MOBILE
    : NB_DEFAULT_TAGS_DISPLAYED_DESKTOP;

  const [displayAllCategoryTags, setDisplayAllCategoryTags] = useState(false);
  const [areTagsHidden, setAreTagsHidden] = useState(false);

  const isDesktopModal = !isMobileDisplay && isModalDisplay;
  const shouldDisplayAllCategoryTags = isDesktopModal || displayAllCategoryTags;

  const tagsLimit = areTagsHidden
    ? 0
    : shouldDisplayAllCategoryTags
    ? undefined
    : NB_DEFAULT_TAGS_DISPLAYED;

  const isCurrentDisplayHandleTagLimitStep = !isDesktopModal;

  const shouldRenderStepUnlockCTA =
    isCurrentDisplayHandleTagLimitStep &&
    !areTagsHidden &&
    tags &&
    tags.length > NB_DEFAULT_TAGS_DISPLAYED;

  const stepperCtaMessage = useMemo(
    () =>
      displayAllCategoryTags
        ? formatMessage(HMCategoryMessages.seeLess)
        : formatMessage(HMCategoryMessages.seeMore),
    [displayAllCategoryTags, formatMessage],
  );

  const { emoji: displayedEmoji, content: displayedContent } = useMemo(
    () => emojiPrefixedParser(content),
    [content],
  );

  const displayedIcon = icon || (
    <FakeIcon color={color} content={displayedEmoji || displayedContent[0].toUpperCase()} />
  );

  const toggleAllCategoryTags = useCallback(() => setDisplayAllCategoryTags(prev => !prev), []);
  const toggleHideTags = useCallback(() => setAreTagsHidden(prev => !prev), []);

  return (
    <div className="HeadingsMenuCategory w-full">
      <HeadingsMenuItem
        className="HeadingMenuCategory-title font-semibold md:text-[18px] cursor-pointer"
        icon={displayedIcon}
        content={displayedContent}
        suffix={<IconArrowDown />}
        onClick={toggleHideTags}
      />
      <div
        className={twMerge(
          'tags-container',
          !isMobileDisplay && isModalDisplay && 'grid grid-cols-3 gap-x-16 gap-y-1 mt-2',
        )}
      >
        {tags?.slice(0, tagsLimit).map(tag => (
          <Link key={tag?.id || tag?.slug} to="category" params={{ tag: tag?.slug }}>
            <a onClick={onTagClick}>
              <HeadingsMenuItem
                key={tag?.id || tag?.slug}
                icon={displayedIcon}
                color={color}
                content={
                  <div className="HeadingsMenu-tag cursor-pointer text-[15px] truncate">
                    {tag?.title || tag?.slug}
                  </div>
                }
                suffix={<MenuNbTopicsItems nbItems={tag?.count?.allTopics!} />}
                className={`px-2 -mr-2 rounded-lg`}
              />
            </a>
          </Link>
        ))}
        {shouldRenderStepUnlockCTA && (
          <div
            className={`category-tags-limit-container px-2 -mr-2 text-gray-400 cursor-pointer text-[13px] rounded-lg hover:bg-gray-lighter hover:text-gray-500`}
            onClick={toggleAllCategoryTags}
          >
            <HeadingsMenuItem content={`${stepperCtaMessage}...`} />
          </div>
        )}
      </div>
    </div>
  );
});

export default HeadingsMenuCategory;
