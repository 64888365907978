import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { moderationMessages } from '../../i18n/moderation';
import { ModerationRemovalReason } from './children/ModerationRemovalReason';
import type { ModerationRemovalStatusProps } from './children/ModerationRemovalStatus';
import { ModerationRemovalStatus } from './children/ModerationRemovalStatus';

type ModerationPendingInfosProps = {
  className?: string;
};

export type ModerationRemovalInfosProps = ModerationRemovalStatusProps & {
  className?: string;
};

type ModerationUserVisibilityOnlyInfosProps = Pick<
  ModerationRemovalInfosProps,
  'contentType' | 'className'
>;

export const ModerationPendingInfos = ({ className }: ModerationPendingInfosProps) => (
  <div className={twMerge('text-secondary', className)}>
    <FormattedMessage {...moderationMessages.message.isPending} />
  </div>
);

export const ModerationRemovalInfos = ({
  contentType,
  moderationMotifId,
  shouldDisplayFullStatus,
  className,
}: ModerationRemovalInfosProps) => {
  return (
    <div className={twMerge('flex items-center gap-x-2 text-gray-medium text-sm', className)}>
      <ModerationRemovalStatus
        contentType={contentType}
        moderationMotifId={moderationMotifId}
        shouldDisplayFullStatus={shouldDisplayFullStatus}
      />
      <ModerationRemovalReason moderationMotifId={moderationMotifId} />
    </div>
  );
};

export const ModerationUserVisibilityOnlyInfos = ({
  contentType,
  className,
}: ModerationUserVisibilityOnlyInfosProps) => (
  <div {...{ className }}>
    <FormattedMessage {...moderationMessages[contentType].userVisibilityOnly} />
  </div>
);
