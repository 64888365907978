import type { GdPageType } from './gdRoutes';

export const titleToKeywords = (titleToHandle?: string) => {
  if (!titleToHandle) return '';

  // Remove words with 2 letters or less
  const words = titleToHandle.split(' ').filter(word => word.length > 2);

  // Transform accents
  const normalizeAccents = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Clean special characters
  const cleanSpecialChars = (str: string) => str.replace(/[^a-zA-Z0-9\s]/g, '');

  // Apply transformations
  const transformedWords = words.map(word =>
    cleanSpecialChars(normalizeAccents(word)).toLowerCase(),
  );

  // Sort words alphabetically
  transformedWords.sort((a, b) => a.localeCompare(b));

  // Join words with a comma
  return transformedWords.join(',');
};

export const getPageInitialData = (pageType?: GdPageType, initialData?: any) => {
  switch (pageType) {
    case 'home':
      return initialData?.website;
    // case 'write':
    //   return initialData?.website;
    case 'profile':
      return initialData?.me;
    // case 'search':
    //   return initialData?.website;
    // case 'message':
    //   return initialData?.website;
    // case 'topics':
    //   return initialData?.website;
    case 'topic':
      return initialData?.website.findTopic;
    // case 'categories':
    //   return initialData?.website;
    case 'category':
      return initialData?.website.categoryLoad;
    // case 'groups':
    //   return initialData?.website;
    // case 'group':
    //   return initialData?.website;
    default:
      return undefined;
  }
};
