import { isAxiosError } from 'axios';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import type { PropsWithChildren, ReactElement } from 'react';
import React, { cloneElement, memo, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { failureToast } from '../../../../front/src/components/Toast';
import { AreaBlocks } from '../../../../front/src/contributor/Layout/AreaBlocks';
import {
  AsideContainer,
  FooterContainer,
  HeaderContainer,
  LayoutMainContent,
} from '../../../../front/src/contributor/Layout/layoutContainers';
import { SlidableArea } from '../../../../front/src/contributor/Layout/SlidableArea';
import { editorMessages } from '../../../../front/src/editor/_old/messages';
import { useRouteTransitions } from '../../../../front/src/hooks/useRouteTransitions';
import { useSkipLayout } from '../../../../front/src/hooks/useSkipLayout';
import { useWaitUserLoading } from '../../../../front/src/hooks/useWaitUserLoading';
import { useAppSelector } from '../../../../front/src/redux/hooks';
import { selectGlobalLoading } from '../../../../front/src/redux/slices/utils-slice';
import { applyErrorHandler } from '../../../../front/src/services/axios';
import type { LayoutBlock, WebsiteLayoutProps } from '../../../../front/src/types/layout';
import { handleError } from '../../../../front/src/utils/common-utils';
import { html } from '../../../../front/src/utils/dom';
import { isBrowser } from '../../../../front/src/utils/web';
import { useHasAds } from '../../utils/common';
import { groupByBlockLocation } from '../../utils/layout';
import { useScrollTopOnRouteChange } from '../../utils/scrollTopOnRouteChange';
import { useOpenChatFromUrl } from '../Chat/chatUrlWatcher';
import { Footer } from '../Footer';
import HeaderNavbar from '../Header';
import { PoweredByGraphDebateBlock } from '../PoweredByGraphDebate';
import { FloatingLoaderWithTimer } from './FloatingLoaderWithTimer';
import { useAuthFilteredCustomLayout } from './useUpdateCustomLayout';

// import { ThirdParties } from '../ThirdParties';
const ThirdParties = dynamic(() => import('../ThirdParties'), { ssr: false });

export type GraphDebateLayoutProps = WebsiteLayoutProps /* <PsychoDataType> */ & {};

interface Props extends GraphDebateLayoutProps {
  customLayout: LayoutBlock[];
}

export const MainLayout: React.FC<PropsWithChildren<Props>> = memo(function MainLayout(props) {
  let {
    children,
    hideLayoutError,
    // externalData = {},
    customLayout = [],
    pageConfig,
  } = props;
  const [dynamicStickyHeaderHeight, setDynamicStickyHeaderHeight] = useState(0);

  // const BlockExternal = useMemo(
  //   () => <BlockAlikeUrl externalData={externalData} />,
  //   [externalData],
  // );

  // const withCustomHead = useAsync(
  //   () => (debateConfig.shouldPreventCustomHead ? new Promise(() => null) : onLoadPromise),
  //   [],
  // );

  // const hasSidebar = layout.some(block => ['left', 'right'].includes(block.locate));

  const { error } = useRouteTransitions();
  const loading = useAppSelector(selectGlobalLoading);

  useWaitUserLoading();
  // useUserLocale();

  const skipLayout = useSkipLayout();

  customLayout = useAuthFilteredCustomLayout(customLayout);

  const customHead = useMemo(() => {
    const headBlocks = customLayout.filter(elt => elt.locate === 'head');
    return headBlocks.map(({ content, name }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Head key={`custom-head---${name}---${index}`}>
        {typeof content === 'string' ? html(content) : content}
      </Head>
    ));
  }, [customLayout]);

  // const staticPropsLayout = customLayout;
  // const reduxLayout = useAppSelector(selectCustomLayout);

  // customLayout = reduxLayout || staticPropsLayout;

  const sortedCustomLayout = useMemo(() => groupByBlockLocation(customLayout), [customLayout]);
  const {
    header: headerAreaLayout,
    footer: footerAreaLayout,
    left: leftAreaLayout,
    right: rightAreaLayout,
    content: contentAreaLayout,
  } = sortedCustomLayout;

  // check if there is only blocks to display in main area
  const isOneColomnLayout = ![leftAreaLayout, rightAreaLayout].some(layout =>
    layout?.some(block => block.device.includes('desktop')),
  );

  // check if there is blocks to display in both left and right areas
  const isThreeColomnsLayout = [leftAreaLayout, rightAreaLayout].every(layout =>
    layout?.some(block => block.device.includes('desktop')),
  );

  const shouldExtendMainContainerWidth = isOneColomnLayout || isThreeColomnsLayout;

  // @TODO fix me !! should be using refs or not run at all
  useEffect(() => {
    if (!isBrowser) return;
    // if there's a sticky header we need to add margin to sidebar elements
    const stickyHeader = customLayout?.find(block => block.locate === 'header' && block.sticky);
    if (stickyHeader && !skipLayout) {
      // find the block (must be the block in area "header")
      const $sticky = window.document.querySelector<HTMLDivElement>(`#block-${stickyHeader._id}`);
      // get its height
      const height = $sticky?.offsetHeight ?? 0;

      setDynamicStickyHeaderHeight(height);

      // add margin to every sticky block in the sidebar
      const $sidebar = window.document.querySelectorAll<HTMLDivElement>(
        '.layout-block.locate-left.is-sticky, .layout-block.locate-right.is-sticky',
      );

      Array.from($sidebar).forEach($block => {
        // eslint-disable-next-line no-param-reassign
        $block.style.top = `${height + 2}px`;
      });
    }
  }, [customLayout, skipLayout]);

  // useEffect(() => {
  //   // add margin to whole page
  //   window.document.body.style.marginTop = `${dynamicStickyHeaderHeight}px`;
  // }, [dynamicStickyHeaderHeight]);

  useEffect(
    () =>
      applyErrorHandler(axiosError => {
        const isAxiosErr = isAxiosError(axiosError);
        const data: any = (isAxiosErr && axiosError.response?.data) || {};

        // Custom config: `noError` to avoid showing the error toast.
        if (isAxiosErr && !(axiosError.config as any).noError) {
          const err = data.error || data.err || data;
          const msg = err.message || err.msg || err.name || err.description || err;

          const msgExtended = msg || axiosError.message;
          const knownMessage =
            msg in editorMessages ? editorMessages[msg as keyof typeof editorMessages] : undefined;
          failureToast(
            knownMessage ? (
              <FormattedMessage {...knownMessage} values={{ error: msg }} />
            ) : msg ? (
              <>{typeof msg === 'string' ? msg : JSON.stringify(msg)}</>
            ) : (
              <FormattedMessage {...editorMessages.genericError} values={{ error: msgExtended }} />
            ),
          );
        }

        if (!isBrowser || process.env.NODE_ENV !== 'production') {
          if (isAxiosErr) {
            handleError(axiosError.message, axiosError.config);
          } else {
            handleError(axiosError.message);
          }
        }
        throw axiosError;
      }),
    [],
  );

  useOpenChatFromUrl();

  useScrollTopOnRouteChange();

  const hasAds = useHasAds();

  //   const previousViewportHeightRef = useRef(isBrowser ? window?.visualViewport?.height : undefined);
  //   const viewportHeightCSSHost = useRef<HTMLDivElement>(null);
  //
  //   useEffect(() => {
  //     if (!isBrowser) return;
  //
  //     // When a mobile device opens the virtual keyboard, conventional values like 100vh and 100% to express "all height available in the viewport" don't work. The content overflows, because the keyboard is not taken into account (at least for some devices).
  //     // The workaround is to read window.visualViewport.height in JavaScript. We use a CSS variable to pass the value to styles.
  //     function handleResize() {
  //       const prevHeight = previousViewportHeightRef.current;
  //       const newHeight = window?.visualViewport?.height;
  //       const styleHost = viewportHeightCSSHost.current;
  //       if (
  //         styleHost &&
  //         (!styleHost?.style.getPropertyValue('--viewport-height') ||
  //           (newHeight && newHeight !== prevHeight))
  //       ) {
  //         styleHost.style.setProperty('--viewport-height', `${newHeight}px`);
  //         previousViewportHeightRef.current = newHeight;
  //       }
  //     }
  //
  //     const debouncedHandle = throttle(handleResize, 50 /* , { leading: false } */);
  //
  //     (window.visualViewport || window).addEventListener('resize', debouncedHandle);
  //
  //     handleResize();
  //
  //     return () => window.removeEventListener('resize', debouncedHandle);
  //   }, []);

  const errorToShow = !hideLayoutError ? error : null;

  return (
    // appLayout doesn't seem to exist or be styled anywhere. To review?
    <>
      {loading && <FloatingLoaderWithTimer enableTooLongWarning />}
      {/* see ThreadNestedMessage.tsx */}
      {/* <div className="debugDetectionZone sticky left-0 right-0 top-[100px] h-[100px] border border-solid border-red-400 w-full" /> */}
      {skipLayout ? (
        <LayoutMainContent error={errorToShow}>{children}</LayoutMainContent>
      ) : (
        <>
          {/* <LayoutFactoryProvider components={{ BlockExternal }}> */}
          {/* {withCustomHead.loading ? null : customHead} */}
          {customHead}

          <AreaBlocks layout={headerAreaLayout} container={HeaderContainer} />

          <HeaderNavbar
            dynamicStickyHeaderHeight={dynamicStickyHeaderHeight}
            pageConfig={pageConfig}
          />
          <div className="relative">
            {isThreeColomnsLayout && (
              <AreaBlocks
                layout={leftAreaLayout}
                container={SlidableArea}
                containerClassName="3colsbreak:!hidden"
                shouldDisplaySlidableContainer={true}
              />
            )}
            <div
              className={twMerge(
                'mainContainer container relative pt-7 md:pt-[65px] pb-8',
                shouldExtendMainContainerWidth && 'laptop:max-w-[1300px] 2xl:max-w-[1300px]',
              )}
            >
              <div id="main-modal" />
              <div className="bodyContainer flex flex-col md:flex-row md:flex-wrap justify-between gap-9 md:min-w-[380px] md:flex-1">
                <AreaBlocks
                  layout={leftAreaLayout}
                  container={AsideContainer}
                  containerClassName={twMerge(isThreeColomnsLayout && 'hidden 3colsbreak:block')}
                />
                <LayoutMainContent error={errorToShow}>
                  {cloneElement(children as ReactElement, { contentAreaLayout })}
                </LayoutMainContent>
                <AreaBlocks layout={rightAreaLayout} container={AsideContainer} />
              </div>

              <PoweredByGraphDebateBlock />

              <Footer />
              <AreaBlocks layout={footerAreaLayout} container={FooterContainer} />

              {isBrowser && customLayout && hasAds && <ThirdParties layout={customLayout} />}
            </div>
          </div>
          {/* </LayoutFactoryProvider> */}
        </>
      )}
    </>
  );
});
