import Image from 'next/image';
import type { FC, MutableRefObject } from 'react';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Link } from '../../../../front/src/components/Link';
import { useWindowSize } from '../../../../front/src/hooks/useWindowSize';
import type { Category } from '../../../../front/src/types/api';
import type { HexColor } from '../../../../front/src/utils/colors';
import { hexWithOpacity } from '../../../../front/src/utils/colors';
import { buildApiFileName } from '../../../../front/src/utils/web';
import { Carousel } from './Carousel';
import styles from './SubjectBox.module.scss';

// 20 being the height of a tag row + gap-2 margin
const tagHeight = 20;
const tagGap = 2 * 4;

// FOR INFORMATION ONLY
// const getCarouselHeight = (maxLinesDisplayed: number) => {
//   return maxLinesDisplayed * tagHeight + (maxLinesDisplayed - 1) * tagGap;
// };

// const carouselHeights = {
//   mobile: { nbRows: 5, height: getCarouselHeight(5) }, // 132px
//   tablet: { nbRows: 8, height: getCarouselHeight(8) }, //216px
//   desktop: { nbRows: 10, height: getCarouselHeight(10) }, //272px
// };

export const tagRowEstimateHeight = tagHeight + tagGap;

export type SubjectBoxPropTypes = {
  tags: Category[] | null;
  title: string;
  background?: string;
  color?: string;
  className?: string;
};

// Display list Element
export const SubjectBox = memo(
  ({ tags, title, background, color, className }: SubjectBoxPropTypes) => {
    const witnessRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [heights, setHeights] = useState(['auto']);
    const { width = 0 } = useWindowSize();

    const cardBackgroudColor = useMemo(
      () => color && hexWithOpacity(color as HexColor, 0.44),
      [color],
    );
    const containerHeight = containerRef.current?.clientHeight ?? 0;

    useEffect(() => {
      if (!witnessRef.current || !containerRef.current) return;
      const witnessRefHeight = witnessRef.current?.scrollHeight;

      if (containerHeight && tags) {
        const nbCarouselPagesExpected = Math.ceil(witnessRefHeight / containerHeight);
        const carouselHeights = Array(nbCarouselPagesExpected)
          .fill(containerHeight)
          .reduce((acc, height, index) => {
            if (index === 0) return [...acc, height];

            const isOnlyUndisplayedGaps =
              witnessRefHeight - height * (index + 1) === tagGap * index;

            return isOnlyUndisplayedGaps ? acc : [...acc, height];
          }, []);
        // Reduce to calculate if we really need to add a new page to the carousel as we remove the first line gap on each new page.
        // so the reduce is mainly to ensure that the last page is not empty

        setHeights(carouselHeights);
      }
    }, [containerHeight, width, tags]);

    const renderChild = useCallback(
      (height, index) => {
        return (
          <CarouselChild
            tags={tags || []}
            witnessRef={witnessRef}
            height={height}
            index={index}
            key={index}
          />
        );
      },
      [tags],
    );

    // TODO: remove as soon as we tested the impact on INP on prod
    // const carrousselChildsToHandle = useMemo(
    //   () => (width < 768 ? [heights[0]] : heights),
    //   [width, heights],
    // );

    return (
      <li
        className={twMerge(styles.listElement, 'relative', className)}
        style={{
          backgroundColor: cardBackgroudColor || undefined,
        }}
      >
        {background && (
          <Image
            src={buildApiFileName(background)}
            alt={`${title} image`}
            className="object-cover object-center"
            fill
            sizes="(min-width: 768px) 307px, (min-width: 536px) 50vw, 100vw"
            quality={51}
            priority
          />
        )}
        <div className="flex justify-center">
          <div className={twMerge(styles.title, 'truncate')}>{title}</div>
        </div>

        <div className="flex flex-1 flex-col overflow-hidden my-3 z-10">
          <Carousel
            contentRef={containerRef as React.MutableRefObject<HTMLDivElement>}
            className="h-[280px] max-h-[132px] @lg:max-h-[216px] @2xl:max-h-[272px]"
          >
            {heights.map(renderChild)}
          </Carousel>
        </div>
      </li>
    );
  },
);

interface CarouselChildProps {
  tags: Category[];
  height: number;
  index: number;
  // Not a clean pattern, but it will take a bit time to refactor. It currently works, so...
  witnessRef: MutableRefObject<HTMLDivElement | null>;
}

const CarouselChild: FC<CarouselChildProps> = memo(function CarouselChild(props) {
  const { tags, height, index, witnessRef } = props;
  return (
    <div className={'overflow-hidden'} ref={index === 0 ? witnessRef : null}>
      <div
        className="flex flex-wrap justify-center gap-2"
        style={{ transform: `translate3d(0, -${(height + tagGap) * index}px, 0)` }}
      >
        {tags.map(({ id, title: catTitle, slug }) => (
          <Link to="category" params={{ tag: slug }} key={id || slug}>
            <a className="text-xs leading-3 bg-white p-1 rounded-lg truncate font-bold cursor-pointer">
              {catTitle || slug}
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
});
