import { FormattedMessage } from 'react-intl';

import { intlReasons, reasonMessages } from '../../../i18n/ban';

export type ModerationRemovalReasonProps = {
  moderationMotifId: string | null;
};

export const ModerationRemovalReason = ({ moderationMotifId }: ModerationRemovalReasonProps) => {
  if (!moderationMotifId) return null;

  return (
    <div className="RemovalReason flex items-center gap-x-1">
      <span>
        <FormattedMessage {...intlReasons.reason} />
      </span>
      <span className="">
        <FormattedMessage {...reasonMessages[moderationMotifId]} />
      </span>
    </div>
  );
};
