import type { OutputData } from '@editorjs/editorjs';
import edjsHtml from 'editorjs-html';

import {
  serializedToExportedString,
  type SerializedValue,
} from '../../thread/ThreadMessage/useSerializeValue';
import { parseJSONString } from '../../utils/json';
import type { EjsBlockData } from './@types';
import {
  embedParser,
  googleFormParserRender,
  googleFormParserSerialize,
  imageParserRender,
  imageParserSerialize,
  linkToolParser,
  quoteParser,
} from './editorjs-parsers';

export type EditorOutputData = Omit<OutputData, 'blocks' | 'version'> & {
  version: string;
  blocks: EjsBlockData[];
};
export interface EditorJSSerialized {
  html: string | null | undefined;
  json: EditorOutputData;
}

const textOrListBlocks = ['header', 'paragraph', 'list', 'quote'] as const;
const isTextOrListBlock = (
  block: EjsBlockData,
): block is Extract<EjsBlockData, { type: (typeof textOrListBlocks)[number] }> =>
  textOrListBlocks.includes(block.type as (typeof textOrListBlocks)[number]);

export const latestEditorVersion = '2';

export function isEditorJSSerialized(value: SerializedValue): value is EditorJSSerialized {
  return !!value && typeof value !== 'string' && !!value.json;
}

export function getVersion(value: SerializedValue) {
  return isEditorJSSerialized(value) ? value.json.version : null;
}

export function serializedToFullHTML(value: SerializedValue) {
  return isEditorJSSerialized(value) ? editorJsOutputDataToHtml(value.json) : value;
}

// https://github.com/pavittarx/editorjs-html
// https://www.npmjs.com/package/editorjs-html
const edjsParserRender = edjsHtml({
  linkTool: linkToolParser,
  quote: quoteParser,
  image: imageParserRender,
  embed: embedParser,
  googleForm: googleFormParserRender,
});
const edjsParserSerialize = edjsHtml({
  linkTool: linkToolParser,
  quote: quoteParser,
  image: imageParserSerialize,
  embed: embedParser,
  googleForm: googleFormParserSerialize,
});

/**
 * Fix ejs br tags parsing that returns <br> instead of <br />.
 */
const fixEjsEmbedsBrokenBrTags = (embedsString: string) => embedsString.replace(/<br>/g, '<br />');

export function editorJsOutputDataToHtml(outputData: OutputData, serialize?: boolean) {
  if (!outputData)
    throw new Error(
      'editorJsOutputDataToHtml cannot convert an undefined json object to HTML. It is likely because it was called with content.json, content being an object with a missing json field.',
    );
  // https://github.com/pavittarx/editorjs-html
  // Alternative: https://github.com/miadabdi/editorjs-parser
  const parser = serialize ? edjsParserSerialize : edjsParserRender;
  const html = parser.parse(outputData).join('\n');
  return html;
}

export function updateContentAndEmbedWithEditorjsOutput(
  content: SerializedValue,
  hiddenEmbeds: string[] = [],
) {
  let contentHtml = serializedToExportedString(content) || '';
  let embeds: string | undefined = undefined;
  if (isEditorJSSerialized(content)) {
    hiddenEmbeds = hiddenEmbeds.slice(0, -1);
    embeds = JSON.stringify(content.json);
  }
  return [contentHtml, embeds, hiddenEmbeds] as const;
}

export function unserializeEditorDbEntry(
  content: string | null | undefined,
  embeds: string | undefined,
): SerializedValue {
  if (!embeds) return content;

  const cleanEmbedsString = fixEjsEmbedsBrokenBrTags(embeds);
  const json = parseJSONString<EditorOutputData>(cleanEmbedsString, console.warn);
  return json ? { html: content, json } : content;
}

// Unfortunately, I couldn't make it work in our case, and I don't know why. Maybe editorjs has something specific that prevents the focus from happening as it should. But it works in a codepen, when opened on iOS: https://codepen.io/antoine_ol/pen/QWJeWNy
// Source: https://stackoverflow.com/a/55652503/4053349
// It's a crazy workaround I would never have imagined myself, but it is the only working way I've found to open the iOS keyboard in this scenario:
// - Use iOS Safari
// - The user clicks "Answer"
// - Async: we open the modal, load the Editorjs code, instantiate it...
// - Programmatically set the focus on the editor + open the keyboard => That part fails with other solutions (e.g. the simple `focus()`)
let fakeInput: HTMLInputElement | undefined = undefined;

export function startOpenEditorStartFocusWorkaround() {
  // create invisible dummy input to receive the focus first
  if (fakeInput) {
    fakeInput.remove();
  }
  fakeInput = document.createElement('input');
  fakeInput.setAttribute('type', 'text');
  fakeInput.setAttribute('readonly', 'true');
  fakeInput.style.position = 'absolute';
  fakeInput.style.opacity = '0';
  fakeInput.style.height = '0';
  fakeInput.style.fontSize = '16px'; // disable auto zoom

  // you may need to append to another element depending on the browser's auto
  // zoom/scroll behavior
  document.body.prepend(fakeInput);

  // focus so that subsequent async focus will work
  fakeInput.focus();
}

export function endOpenEditorEndFocusWorkaround(element: HTMLElement | null) {
  // now we can focus on the target input
  element?.focus();

  // cleanup
  fakeInput?.remove();
  fakeInput = undefined;
}

export function limitEditorJsonContent(
  editorJsonContent: EditorJSSerialized['json'],
  limit?: number,
) {
  if (limit === undefined) return editorJsonContent;

  const limitedJson = JSON.parse(JSON.stringify(editorJsonContent)) as EditorJSSerialized['json'];

  const paragraphs = limitedJson.blocks.filter(isTextOrListBlock);
  if (!paragraphs) return editorJsonContent;

  let count = 0;

  for (let i = 0; i < paragraphs.length; i++) {
    const block = paragraphs[i];

    const isListBlock = block.type === 'list';

    if (isListBlock) {
      // handle length check over list items
      for (let item of block.data.items) {
        const itemTextLength = item.length;
        count += itemTextLength;

        if (count > limit) {
          const remaining = limit - (count - itemTextLength);
          item = item.slice(0, remaining);
          break;
        }
      }
    } else {
      const blockTextLength = block.data.text.length;
      count += blockTextLength;

      if (count > limit) {
        const remaining = limit - (count - block.data.text.length);
        block.data.text = block.data.text.slice(0, remaining);
        limitedJson.blocks = paragraphs.slice(0, i + 1);
        break;
      }
    }
  }

  return limitedJson;
}
