export type HexColor = `#${string}`;

/**
 * Converts opacity between 0 and 1 (like rgba) in hexadecimal
 *
 * @example
 * console.log(getHexOpacity(0.1));
 * // 19
 */
const getHexOpacity = (opacity: number) => {
  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be between 0 and 1');
  }

  // Converts opacity to integer between 0 and 255
  const decimalOpacity = Math.round(opacity * 255);

  // Converts to hexadecimal with 2 figures
  const hexOpacity = decimalOpacity.toString(16).padStart(2, '0');

  return hexOpacity;
};

/**
 * Converts hex color to hex color with opacity
 *
 * @param hex 6 chars hex string beguinning with #
 * @param opacity value between 0 and 1
 *
 * @example
 * console.log(hexWithOpacity('#00FF00', 0.1));
 * // "#00FF0019"
 */
export const hexWithOpacity = (hexColor: HexColor, opacity: number) => {
  const hexCode = hexColor.slice(1);
  let hexToHandle = hexCode;

  if (hexCode.length === 3) {
    hexToHandle = hexCode
      .split('')
      .map(char => `${char}${char}`)
      .join('');
  }

  const hexOpacity = getHexOpacity(opacity);
  return `#${hexToHandle}${hexOpacity}`;
};

/**
 * Converts hex color to rgba
 *
 * @example
 * console.log(hexToRgba('#00FF00'));
 * // "rgba(0, 255, 0, 1)"
 *
 * console.log(hexToRgba('#00FF001A'));
 * // "rgba(0, 255, 0, 0.1)"
 */
export const hexToRgba = (hex: string) => {
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  if (hex.length !== 6 && hex.length !== 8) {
    throw new Error('Input must be a 6 or 8 char hex string');
  }

  // Convert hex string to integer
  const num = parseInt(hex, 16);

  // Extract R, G, B components
  const red = (num >> 16) & 255;
  const green = (num >> 8) & 255;
  const blue = num & 255;

  let opacity = 1;

  // Get opacity if present
  if (hex.length === 8) {
    opacity = Math.round((num & 0xff) / 2.55) / 100;
  }

  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
};

/**
 * Extracts R, G, B values from hex color
 *
 * @example
 * console.log(hexToSplittedRgbObject('#00FF00'));
 * // { r: 0, g: 255, b: 0 }
 */
export const hexToSplittedRgbObject = (hex: string) => {
  hex = hex.replace('#', '');
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4), 16);
  return { r, g, b };
};

/**
 * Converts a single R, G, B value to hex
 *
 * @example
 * console.log(rgbValueToHex(0));
 * // 00
 *
 * console.log(rgbValueToHex(255));
 * // FF
 */
const rgbValueToHex = (n: number): string => {
  const hex = n.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

/**
 * Converts R, G, B values to hex color
 *
 * @example
 * console.log(rgbToHex(0, 255, 0));
 * // #00FF00
 */
const rgbToHexColor = (r: number, g: number, b: number) => {
  return `#${rgbValueToHex(r)}${rgbValueToHex(g)}${rgbValueToHex(b)}`;
};

export const lightenHexColor = (hex: string, percent: number) => {
  const { r, g, b } = hexToSplittedRgbObject(hex);
  const newR = Math.min(255, Math.round(r + ((255 - r) * percent) / 100));
  const newG = Math.min(255, Math.round(g + ((255 - g) * percent) / 100));
  const newB = Math.min(255, Math.round(b + ((255 - b) * percent) / 100));
  return rgbToHexColor(newR, newG, newB);
};

export const isWhiteColorHex = (hex?: string) => {
  if (!hex) return false;
  return ['#fff', '#ffffff'].includes(hex.toLowerCase());
};
