import classNames from 'classnames';
import { type FC, type ForwardedRef, type InputHTMLAttributes, memo, type ReactNode } from 'react';

export type InputPropTypes = InputHTMLAttributes<HTMLElement> & {
  name?: string;
  error?: Error | string;
  value: string;
  className?: string;
  containerClassName?: string;
  inputRef?: ForwardedRef<HTMLInputElement>;
  beforeElement?: ReactNode;
  afterElement?: ReactNode;
  afterElementNoFocus?: ReactNode;
  overrideWidth?: boolean;
  overrideHeight?: boolean;
  skipLeftPadding?: boolean;
  wrap?: boolean;
  noBorder?: boolean;
  forceContainer?: boolean;
};

export const inputWrapperClasses = 'flex items-center relative';

const commonContainerClass =
  'w-full px-3 sm:bg-white rounded font-source appearance-none outline-none transition focus-within:shadow text-input-text text-base';

const commonInputClass = '_md:min-h-unset appearance-none outline-none peer';
const inputInContainerClass = 'bg-transparent';

const containerClassNameNormalState = 'border-input-border focus-within:bg-input-bg';
const errorContainerClassName = 'border-input-error bg-input-bg';

export const Input: FC<InputPropTypes> = memo(function Input(props) {
  const {
    error,
    type = 'text',
    beforeElement,
    afterElement,
    afterElementNoFocus,
    className,
    containerClassName,
    inputRef,
    overrideWidth = false,
    overrideHeight = false,
    skipLeftPadding,
    wrap,
    noBorder,
    forceContainer,
    ...inputProps
  } = props;
  const hasSiblings = beforeElement || afterElement || afterElementNoFocus;
  const containerClass = error ? errorContainerClassName : containerClassNameNormalState;
  let inputClassName2 =
    /* hasSiblings ? classNames('flex-1', commonInputClass) : */ commonInputClass;
  if (!overrideWidth) {
    inputClassName2 = `${inputClassName2} w-full`;
  }
  if (!overrideHeight) {
    inputClassName2 = `${inputClassName2} h-input`;
  }
  let commonContainerClass2 = classNames(
    commonContainerClass,
    containerClassName,
    skipLeftPadding && 'pl-0',
    wrap && 'flex-wrap',
    !noBorder && 'rounded-[5px] border border-solid',
  );
  const inputInContainerHere = forceContainer || hasSiblings;

  return inputInContainerHere ? (
    <div className={classNames(commonContainerClass2, containerClass, inputWrapperClasses)}>
      {beforeElement}
      <input
        type={type}
        ref={inputRef}
        className={classNames(inputClassName2, inputInContainerClass, className)}
        {...inputProps}
      />
      {afterElement}
    </div>
  ) : (
    <>
      {beforeElement}
      <input
        type={type}
        ref={inputRef}
        className={classNames(inputClassName2, commonContainerClass2, containerClass, className)}
        {...inputProps}
      />
      {afterElement}
    </>
  );
});
