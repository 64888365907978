import type React from 'react';
import type { ToastOptions } from 'react-toastify';
import { Slide, toast } from 'react-toastify';

export { ToastContainer } from 'react-toastify';

const toastConfig: ToastOptions = {
  position: 'top-center',
  transition: Slide,
  bodyClassName: 'ml-2 text-center',
  autoClose: 4000,
  draggable: false,
  pauseOnFocusLoss: true,
} as const;

export const confirmationToast = (str: React.ReactNode, options: ToastOptions = {}) => {
  toast.info(str, {
    ...toastConfig,
    ...options,
  });
};

export const failureToast = (str: React.ReactNode, options: ToastOptions = {}) => {
  toast.error(str, {
    ...toastConfig,
    ...options,
  });
};

export const successToast = (str: React.ReactNode, options: ToastOptions = {}) => {
  toast.success(str, {
    ...toastConfig,
    ...options,
  });
};

export const warnToast = (str: React.ReactNode, options: ToastOptions = {}) => {
  toast.warn(str, {
    ...toastConfig,
    ...options,
  });
};
