import Head from 'next/head';
import React from 'react';

import { useConnectedRouter } from '../../../../front/src/hooks/useConnectedRouter';
import { usePermissions } from '../../../../front/src/hooks/usePermissions';
import type { DatalayerMeta } from '../../../../front/src/types/metaDatalayer';
import { getCleanPathname } from '../../../../front/src/utils/clean-routes-utils';
import type { GdRoutePathname } from '../../../../front/src/utils/gdRoutes';
import { getPageType } from '../../../../front/src/utils/gdRoutes';
import { getPageInitialData, titleToKeywords } from '../../../../front/src/utils/metaDatalayer';
import { isMobile } from '../../../../front/src/utils/web';

const MetaDatalayerBlock = ({ pageData }) => {
  const { website, initialData, customLayout } = pageData;

  const datalayerMetas = customLayout.reduce((acc, layoutEl) => {
    if (layoutEl?.type !== 'meta-datalayer') return acc;
    return [...acc, ...layoutEl.options];
  }, []) as DatalayerMeta[];

  const { pathname, basePath, asPath, locale } = useConnectedRouter();
  const { isAuthDone, authUserId } = usePermissions();

  const isLoggedIn = !!(isAuthDone && authUserId);

  const isMobileDevice = isMobile();
  const cleanPathname = getCleanPathname(pathname);
  const currentPageType = getPageType(cleanPathname as GdRoutePathname);

  const pageInitialData = getPageInitialData(currentPageType, initialData);
  const pageTitle = pageInitialData?.title || pageInitialData?.subject;
  const pageKeywords = titleToKeywords(pageTitle);

  const metaDatas: Record<DatalayerMeta['name'], string | undefined> = {};

  for (const meta of datalayerMetas) {
    if (meta.type === 'custom') metaDatas[meta.name] = meta.content;
    if (meta.type === 'site') metaDatas[meta.name] = website?.name;
    if (meta.type === 'login') metaDatas[meta.name] = isLoggedIn ? '1' : '0';
    if (meta.type === 'platform') metaDatas[meta.name] = isMobileDevice ? 'mobile' : 'desktop';
    if (meta.type === 'url') metaDatas[meta.name] = `${basePath}${asPath}`;
    if (meta.type === 'pathname') metaDatas[meta.name] = asPath.split('?')[0];
    if (meta.type === 'title') metaDatas[meta.name] = pageTitle;
    if (meta.type === 'title_keywords') metaDatas[meta.name] = pageKeywords;
    if (meta.type === 'locale') metaDatas[meta.name] = locale;
    if (meta.type === 'main_tag') metaDatas[meta.name] = pageInitialData?.categories?.[0]?.title;
    if (meta.type === 'tags')
      metaDatas[meta.name] = pageInitialData?.categories?.map(elt => elt.title).join(',');
  }

  const metas = Object.entries(metaDatas)
    .filter(([_, value]) => value)
    .map(([key, value]) => {
      const propertyNameToDisplay = key.startsWith('og:') ? 'property' : 'name';
      const metaProps = { [propertyNameToDisplay]: key, content: value };
      return <meta key={key} {...metaProps} />;
    });

  return <Head>{metas}</Head>;
};

export default MetaDatalayerBlock;
