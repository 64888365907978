import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import type { EditorJSSerialized } from '../../editor/editorjs/editorjs-utils';
import { visibleEditorValue } from '../../utils/validation';
import { isBrowser } from '../../utils/web';

export type SerializedValue = string | EditorJSSerialized | null | undefined;
export type SerializedValueDefined = string | EditorJSSerialized;
export type InitialValueRef = MutableRefObject<string | EditorJSSerialized | undefined>;
export type SerializeValue = (value: SerializedValue) => void;
export type ClearValue = () => void;

/**
 * This hook should make it easier to serialize what's being typed in the text editor, and restore it if refreshing the text editor.
 * @param serializeId A unique identifier to serialize the value. It should uniquely identify the text editor instance among other instances. If 2 instances share the same ID, the serialized values will collide.
 * @returns Tuple (array) with 3 values:
 * - initialValueRef: a reference (from useRef) to read the initial value.
 * - serializeValue: a function to call each time the text editor value changes, to serialize it.
 * - clearValue: shortcut for serializeValue(null). Clears the serialized value. Call it when the user submits or explicitly closes the editor.
 */
export function useSerializeValue(serializeId: string) {
  const key = `useSerializeValue_${serializeId}`;

  const initialValueRef: InitialValueRef = useRef<SerializedValueDefined>();

  // Effect to update initialValueRef on refresh (e.g. no serializedId on first render)
  useEffect(() => {
    const storedValue = readLocalStorage(key);
    initialValueRef.current = storedValue;
  }, [key]);

  const serializeValue: SerializeValue = useCallback(
    (value: string | EditorJSSerialized | null | undefined) => {
      if (!isBrowser) return;

      const hasNothingToStore = visibleEditorValue(value)?.trim() === '';

      if (value == null || hasNothingToStore) {
        // Clear the persisted value
        initialValueRef.current = undefined;
        localStorage.removeItem(key);
      } else {
        initialValueRef.current = value;
        localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
      }
    },
    [initialValueRef, key],
  );

  const clearValue: ClearValue = useCallback(() => {
    if (!isBrowser) return;
    initialValueRef.current = undefined;
    localStorage.removeItem(key);
  }, [initialValueRef, key]);

  return [initialValueRef, serializeValue, clearValue] as const;
}

export function serializedToExportedString(value: SerializedValue) {
  return typeof value === 'string' ? value : value?.html;
}

function readLocalStorage(key: string) {
  if (!isBrowser) return undefined;
  const val = localStorage.getItem(key);
  if (val == null) return undefined;
  try {
    return JSON.parse(val);
  } catch (error) {
    return val;
  }
}
