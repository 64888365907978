import { isRemovedContent } from '../../../utils/status';

const commonDsaClasses =
  'relative bg-gray-light border-l-4 border-l-primary opacity-90 pointer-events-none';

export const dsaClassHandler = (status?: string) => {
  switch (true) {
    case isRemovedContent(status):
      return `${commonDsaClasses} border-l-primary`;
    case status === 'pending':
      return `${commonDsaClasses} border-l-secondary`;
    default:
      return;
  }
};
