import type { Website } from '../../../front/src/types/api';

const DEFAULT_LOCALE = 'fr';

export const getLocaleFromBrowser = () => {
  if (typeof window === 'undefined') return null;
  const locale = navigator.language;
  return locale.split('-')[0];
};

export const getDefaultLocaleFromWebsite = (website?: Website) => {
  if (!website) return null;

  const { languages } = website;
  if (!languages || languages.length === 0) return null;

  const locale = languages[0] || DEFAULT_LOCALE;
  return locale;
};
