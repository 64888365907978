import { gql } from '@apollo/client';
import type { ParsedUrlQuery } from 'querystring';

import { frontConfig } from '../../config';
import axios from '../../services/axios';
import type { Graphdebate } from '../../types/api';
import type { ALLOWED_SORTS } from '../ThreadMessages/query';
import { AuthorFragment } from './thread';

export const TopicBodyFragment = gql`
  fragment TopicBodyFragment on Topic {
    id
    slug
    subject
    content
    hiddenEmbeds
    embeds
    createdAt
    contentEditedAt
    pinned
    isFavorite
    identifier
    status
    lastMessage {
      id
      createdAt
    }
    messagesCount
    votes {
      score
      user {
        id
      }
    }
    author {
      ...AuthorFragment
    }
    categories {
      id
      title
      description
      slug
      isAdsSafe
      status
      adult
    }
    dup
    group {
      id
    }
  }
  ${AuthorFragment}
`;

export const TopicListItemActionsFragment = gql`
  fragment TopicListItemActionsFragment on Topic {
    id
    pinned
    isFavorite
  }
`;

export const TopicListItemFragment = gql`
  fragment TopicListItemFragment on Topic {
    id
    subject
    content @skip(if: $skipContent)
    slug
    author {
      ...AuthorFragment
    }
    createdAt
    messagesCount
    lastMessage {
      id
      author {
        ...AuthorFragment
      }
      createdAt
    }
    categories {
      id
      slug
      title
    }
    votes {
      score
      user {
        id
      }
    }
  }
  ${AuthorFragment}
`;

export const topicsFilterQuery = gql`
  query topicsFilterQuery(
    $page: Int
    $limit: Int
    $dateStart: GraphQLDateTime
    $dateEnd: GraphQLDateTime
    $filter: String
    $skipContent: Boolean = false
  ) {
    website {
      id
      name
      topicList(
        page: $page
        limit: $limit
        dateStart: $dateStart
        dateEnd: $dateEnd
        filter: $filter
      ) {
        meta {
          count
          page
          limit
        }
        topics {
          id
          ...TopicListItemFragment
          ...TopicListItemActionsFragment
        }
      }
    }
  }
  ${TopicListItemFragment}
  ${TopicListItemActionsFragment}
`;

export const topicsProfileQuery = gql`
  query topicsProfileQuery(
    $limit: Int
    $page: Int
    $nextPage: Int
    $pseudo: String
    $sort: String
    $skipContent: Boolean = false
  ) {
    profile(pseudo: $pseudo) {
      id
      topicList(page: $page, limit: $limit, sort: $sort) {
        meta {
          count
        }
        topics {
          id
          ...TopicListItemFragment
          status
          moderationMotifId
        }
      }
      nextTopicList: topicList(page: $nextPage, limit: $limit, sort: $sort) {
        topics {
          id
          ...TopicListItemFragment
          status
        }
      }
    }
  }
  ${TopicListItemFragment}
`;

export const TopicQuery = gql`
  query TopicQuery($slug: String) {
    website {
      id
      findTopic(slug: $slug) {
        id
        ...TopicBodyFragment
        findOrCreateThread(offset: 0, limit: 0) {
          id
          countMessages
          countRootMessages
        }
      }
    }
  }
  ${TopicBodyFragment}
`;

export const homeTopicsQuery = gql`
  query HomeTopicsQuery($limit: Int = 20, $sort: String, $skipContent: Boolean = false) {
    website {
      id
      name
      topicList(page: 1, limit: $limit, sort: $sort) {
        meta {
          count
        }
        topics {
          id
          ...TopicListItemFragment
        }
      }
    }
  }
  ${TopicListItemFragment}
`;

export const HOME_DEFAULT_SORT = 'relevance';
export const HOME_DEFAULT_LIMIT = 10;

export function makeHomeTopicsVariables(graphdebate: Graphdebate, query: ParsedUrlQuery) {
  if (!graphdebate) throw new Error('makeHomeTopicsVariables needs graphdebate which is falsy.');
  const { latestTopicsSortOrder, countPerListPage } = graphdebate;
  let sort0: ALLOWED_SORTS =
    (query.sort as ALLOWED_SORTS) || latestTopicsSortOrder || HOME_DEFAULT_SORT;
  // const sort = REDIRECT_SORT_API[sort0]; // If we need to map newest to newestLine for the API
  const sort = sort0;
  // Hack: 20 is the hard-coded max, until the back-office can provide a specific setting for the home page limit.
  // countPerListPage is a global setting that also impacts the tags pages.
  const limit = Math.min(countPerListPage || HOME_DEFAULT_LIMIT, 20);
  return { limit, sort };
}

export const adminDeleteTopic = ({
  motifId,
  motif,
  pageId,
}: {
  pageId: string | number;
  motifId?: string | number;
  motif?: string;
}) => {
  if (motifId === undefined) {
    const errorMsg = 'Cannot delete an admin topic without a motif_id.';
    frontConfig.IS_DEV && console.error(errorMsg);
    throw new Error(errorMsg);
  }
  return axios.put(`/api/page/${pageId}/status`, {
    status: 'deleted',
    motif_id: motifId,
    motif,
  });
};

export const bookmarkTopic = async (pageId: string, newfavoriteValue: boolean) => {
  const { data } = await axios.post(`/api/page/${pageId}/favorite`, {
    favorite: newfavoriteValue,
  });

  return data;
};

export const deleteTopic = ({ pageId }: { pageId: string | number }) =>
  axios.delete(`/api/page/${pageId}`);

export const pinTopic = async ({ pageId, unPin }: { pageId: string; unPin?: boolean }) => {
  const { data } = await axios.post(`/api/page/${pageId}/pinned`, { pinned: !unPin });

  return data;
};

export const GetTopicQuery = gql`
  query GetTopicQuery($slug: String, $hasSlug: Boolean!) {
    me {
      profile {
        getGroups {
          groups {
            id
            name
          }
        }
      }
    }
    website @include(if: $hasSlug) {
      id
      findTopic(slug: $slug) {
        id
        ...TopicBodyFragment
      }
    }
  }
  ${TopicBodyFragment}
`;

export const CreateOrUpdateTopicMutation = gql`
  mutation CreateOrUpdateTopicMutation(
    $topicId: ID
    $subject: String!
    $content: String!
    $hiddenEmbeds: [String]
    $embeds: String
    $categoryIds: [ID]
    $authorNotification: Boolean
    $groupId: ID
    $adminBoost: Boolean
    $adminBoostValue: Int
    $guestUserName: String
    $guestEmail: String
    $fileIds: [ID]
  ) {
    createOrUpdateTopic(
      topicId: $topicId
      subject: $subject
      content: $content
      hiddenEmbeds: $hiddenEmbeds
      embeds: $embeds
      categoryIds: $categoryIds
      authorNotification: $authorNotification
      groupId: $groupId
      adminBoost: $adminBoost
      adminBoostValue: $adminBoostValue
      guestUserName: $guestUserName
      guestEmail: $guestEmail
      fileIds: $fileIds
    ) {
      id
      ...TopicBodyFragment
    }
  }
  ${TopicBodyFragment}
`;
