import { ApolloProvider } from '@apollo/client';
import type { PropsWithChildren } from 'react';
import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { AuthProvider } from '../../../../front/src/hooks/usePermissions';
import { useApollo } from '../../../../front/src/services/apollo/initApollo';
import { gcAuthInterceptor } from '../../../../front/src/services/axios';
import { AuthWithPromiseProvider } from '../../../../front/src/utils/graphlogin/AuthWithPromiseProvider';
import { useLanguage } from '../context/LanguageContext';
import { SubscribeToTokenProvider } from './HasTokenProvider';
import { getGraphLogin } from './LoadGraphLogin';

type AuthManagerProps = {
  pageProps: any;
  publicKey: string;
};

export const AuthManager: React.FC<PropsWithChildren<AuthManagerProps>> = memo(
  function AuthManager({ pageProps, publicKey, children }) {
    const { currentLanguage } = useLanguage();
    const { apolloClient, auth, getToken } = useApolloInBrowser(
      pageProps,
      publicKey,
      currentLanguage,
    );

    return (
      <SubscribeToTokenProvider getToken={getToken}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider graphLogin={auth}>
            <AuthWithPromiseProvider>{children}</AuthWithPromiseProvider>
          </AuthProvider>
        </ApolloProvider>
      </SubscribeToTokenProvider>
    );
  },
);

function useApolloInBrowser(pageProps: any, publicKey: string, locale: string) {
  const auth = useMemo(() => getGraphLogin(publicKey, locale), [locale, publicKey]);
  const { getToken, subscribeToToken, fetchGraphLogin, logout } = auth;

  const getPublicKey = useCallback(() => publicKey, [publicKey]);

  const triggerRefreshToken = useCallback(
    () => fetchGraphLogin('triggerRefreshToken'),
    [fetchGraphLogin],
  );

  const apolloClient = useApollo(
    { getPublicKey, getToken, triggerRefreshToken, logout },
    pageProps,
  );

  // Resetting the apollo store on all token change causes all kinds of bugs and side effects, with the store being reset while a gql query is in progress.
  // Let's reset only when logging out, i.e. no token. So that we don't reset when the token is refreshed.
  useEffect(() => {
    return subscribeToToken((token, prevToken) => {
      if ((prevToken && !token) || (!prevToken && token)) {
        apolloClient.resetStore();
      }
    });
  }, [subscribeToToken, apolloClient]);

  useEffect(
    () => gcAuthInterceptor({ getPublicKey, getToken, triggerRefreshToken }),
    [getPublicKey, getToken, logout, triggerRefreshToken],
  );

  // We used to return getToken here, but it's not used anymore.
  return { apolloClient, auth, getToken /* , subscribeToToken */ };
}
