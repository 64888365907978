import type { ModerationRemovalInfosProps } from '../../../components/Moderation/ModerationInfos';
import {
  ModerationPendingInfos,
  ModerationRemovalInfos,
} from '../../../components/Moderation/ModerationInfos';
import { isRemovedContent } from '../../../utils/status';

type DsaMessageProps = Partial<ModerationRemovalInfosProps> & {
  contentStatus?: string;
};

export const DsaMessage = ({
  contentType = 'message',
  contentStatus,
  moderationMotifId = null,
  className,
}: DsaMessageProps) => {
  switch (true) {
    case isRemovedContent(contentStatus):
      return (
        <ModerationRemovalInfos
          contentType={contentType}
          moderationMotifId={moderationMotifId}
          shouldDisplayFullStatus={false}
          className={className}
        />
      );
    case contentStatus === 'pending':
      return <ModerationPendingInfos className={className} />;
    default:
      return null;
  }
};
