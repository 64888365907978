import { defineMessages } from 'react-intl';

const topicModerationMessages = defineMessages({
  deletedByUser: { defaultMessage: "Ce sujet a été retiré par l'utilisateur.", id: 'RkIgdw' },
  deletedByModerator: {
    defaultMessage: 'Ce sujet a été retiré par le modérateur.',
    id: 'vfTwJ8',
  },
  userVisibilityOnly: {
    defaultMessage: 'Ce sujet est visible uniquement par vous.',
    id: 'wwLaB5',
  },
});

const commentModerationMessages = defineMessages({
  isPending: {
    defaultMessage: 'Votre commentaire est en attente de publication.',
    id: '4/tXNu',
  },
  deletedByUser: {
    defaultMessage: "Ce message a été retiré par l'utilisateur.",
    id: 'Aa6K6c',
  },
  deletedByModerator: {
    defaultMessage: 'Ce message a été retiré par le modérateur.',
    id: 'nUOvys',
  },
  userVisibilityOnly: {
    defaultMessage: 'Ce message est visible uniquement par vous.',
    id: 'PznmD8',
  },
});

const defaultModerationMessages = defineMessages({
  deleted: { defaultMessage: 'Supprimé', id: 'eiKtaA' },
});

export const moderationMessages = {
  topic: topicModerationMessages,
  message: commentModerationMessages,
  default: defaultModerationMessages,
};
