// import { ensureLogRocketIsInitialized } from './logrocket';
//
// ensureLogRocketIsInitialized();
import { isBrowser } from './fragments/isBrowser';

export function handleError(...error: any[]) {
  console.error(...error);
  // TODO restore logrocket once we know how to make it compatible with the nextjs build.
  // Since the adoption of the ISR, switching from getServerSideProps to getStaticProps, it seems the nextjs build checks that all the code must be runnable in the edge runtime, even for dynamic imports. I don't know how to tell the nextjs build that this is browser-only code. The dynamic import doesn't seem to be enough.
  //   (async function () {
  //     // Dynamic import to have a lighter initial bundle. logrocket is not required for the visible rendering.
  //     const LogRocket = await import('logrocket');
  //
  //     for (const err of error) {
  //       LogRocket.captureException(err, {
  //         // tags: {
  //         //   // additional data to be grouped as "tags"
  //         //   subscription: 'Pro',
  //         // },
  //         extra: {
  //           // additional arbitrary data associated with the event
  //           // mixpanelDistinctId: await getDistinctIdMp(),
  //           authUserId: _authUserId || '',
  //         },
  //       });
  //     }
  //   })();
}

export async function wait(delay?: number) {
  return new Promise(resolve => setTimeout(resolve, delay));
}

type PropertyName = string | number | symbol;

export function omitSingle<T extends object, K extends PropertyName>(
  key: K,
  { [key]: _, ...obj }: T,
) {
  return obj;
}

export function omitTwo<T extends object, K extends PropertyName, K2 extends PropertyName>(
  key: K,
  key2: K2,
  { [key]: _, [key2]: _2, ...obj }: T,
) {
  return obj;
}

export function debounce<Fn extends (...args: any) => any>(fn: Fn, delay: number) {
  let timeoutID: NodeJS.Timeout | undefined = undefined;
  const debounced = function (...args: any) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  } as Fn & { cancel: VoidFunction };
  debounced.cancel = () => clearTimeout(timeoutID);
  return debounced;
}

export function throttle<Fn extends (...args: any) => any>(fn: Fn, delay: number) {
  let throttlePause = false;

  const throttled = function (...args: any) {
    if (throttlePause) return;

    fn(...args);
    throttlePause = true;

    setTimeout(() => {
      throttlePause = false;
    }, delay);
  } as Fn;

  return throttled;
}

// const deb = debounce((i: number) => console.log('print', i), 500);
// deb(1);
// setTimeout(() => deb(2), 20);
// setTimeout(() => deb(3), 50);
// setTimeout(() => deb(4), 100);
// setTimeout(() => deb(5), 180);
// setTimeout(() => deb(6), 250);
// setTimeout(() => deb.cancel(), 300);
// setTimeout(() => deb(7), 850);
// setTimeout(() => deb.cancel(), 900);

// // KO for now
// To replace lodash/debounce.
// Alternative: https://github.com/niksy/throttle-debounce
// function throttle0<Fn extends (...args: any) => any>(fn: Fn, delay: number) {
//   var last: number;
//   var timer: NodeJS.Timeout;
//   return function (...args: any) {
//     var now = +new Date();
//     if (last && now < last + delay) {
//       // le délai n'est pas écoulé on reset le timer
//       clearTimeout(timer);
//       timer = setTimeout(function () {
//         last = now;
//         fn(...args);
//       }, delay);
//     } else {
//       last = now;
//       fn(...args);
//     }
//   };
// }
// function throttle<Fn extends (...args: any) => any>(fn: Fn, delay: number) {
//   //initialize throttlePause variable outside throttle function
//   let throttlePause = false;
//   return function (...args: any) {
//     //don't run the function if throttlePause is true
//     if (throttlePause) return;
//     //set throttlePause to true after the if condition. This allows the function to be run once
//     throttlePause = true;
//
//     //setTimeout runs the callback within the specified time
//     setTimeout(() => {
//       fn(...args);
//
//       //throttlePause is set to false once the function has been called, allowing the throttle function to loop
//       throttlePause = false;
//     }, delay);
//   };
// }
//
// // const throt = throttle((i: number) => console.log('print', i), 100);
// const throt = debounceL((i: number) => console.log('print', i), 100, {
//   maxWait: 100,
//   trailing: true,
// });
// throt(1);
// setTimeout(() => throt(2), 20);
// setTimeout(() => throt(3), 50);
// setTimeout(() => throt(4), 100);
// setTimeout(() => throt(5), 180);
// setTimeout(() => throt(6), 250);
// setTimeout(() => throt(7), 850);

// uniqBy<T>(array: List<T> | null | undefined, iteratee: ValueIteratee<T>): T[];
type NotVoid = unknown;
type IterateeShorthand<T> = PropertyName | [PropertyName, any] | PartialShallow<T>;
type PartialShallow<T> = {
  [P in keyof T]?: T[P] extends object ? object : T[P];
};
type ValueIteratee<T> = ((value: Exclude<T, null | undefined>) => NotVoid) | IterateeShorthand<T>;
export function uniqBy<T>(arr: T[], predicate: ValueIteratee<T>) {
  const cb = typeof predicate === 'function' ? predicate : (o: T) => (o as any)[predicate as any];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item as any);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
}

// const sourceArray = [
//   { id: 1, name: 'bob' },
//   { id: 1, name: 'bill' },
//   null,
//   { id: 1, name: 'bill' },
//   { id: 2, name: 'silly' },
//   { id: 2, name: 'billy' },
//   null,
//   undefined,
// ];
//
// console.log('id string: ', uniqBy(sourceArray, 'id'));
//
// console.log(
//   'name func: ',
//   uniqBy(sourceArray, o => o.name),
// );

export function normalizeStr(str: string) {
  // https://stackoverflow.com/a/37511463/505836
  return str
    .toLowerCase()
    .replace(/["'.,\/#!$%\^&\*;:{}=\-_`~()]/g, ' ') // replace special chars with space
    .replace(/\s+/g, ' ') // replace mulitples spaces with one
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, ''); // replace all accents
}

/**
 * The JS native str.slice() can cut a unicode character, resulting in invalid characters. This implementation is aware of unicode characters and slices at the right location. Source: https://stackoverflow.com/a/62341816/4053349
 */
export function sliceUnicodeAware(str: string, start?: number, end?: number) {
  return [...str].slice(start, end).join('');
}

export function readCssNumber(element: HTMLElement, property: string) {
  return parseInt(readCss(element, property));
}

export function readCss(element: HTMLElement, property: string) {
  if (!isBrowser) throw new Error('Not in browser context, cannot read CSS value.');
  return window.getComputedStyle(element, null).getPropertyValue(property);
}

export function stringify(value: any) {
  return value && typeof value !== 'string' ? JSON.stringify(value) : value;
}
