// LocaleProvider.tsx
import type { PropsWithChildren } from 'react';
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { IntlConfig } from 'react-intl';
import { IntlProvider } from 'react-intl';

import { useAppSelector } from '../../../../front/src/redux/hooks';
import {
  getSavedInterfaceLanguage,
  saveInterfaceLanguage,
} from '../../../../front/src/services/storage';
import type { Website } from '../../../../front/src/types/api';
import { getDefaultLocaleFromWebsite, getLocaleFromBrowser } from '../../utils/locale';

/**
 * Get proper messages by reading file system from serverside
 */
const fetchMessagesClient = async (language: string, allowedLanguages: string[]) => {
  try {
    const languageToHandle = allowedLanguages.includes(language) ? language : allowedLanguages[0];
    const response = await fetch(`/api/translations/${languageToHandle}`);
    if (!response.ok) throw new Error('Failed to fetch translations');
    return response.json();
  } catch (error) {
    console.error('Error loading messages:', error);
    return null;
  }
};

type LanguageProviderType = IntlConfig & { website: Website };

type LanguageContextType = {
  currentLanguage: string;
  setCurrentLanguage: (locale: string) => void;
};

const LanguageContext = createContext<LanguageContextType>({
  currentLanguage: 'fr',
  setCurrentLanguage: () => {},
});

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = memo(function LanguageProvider({
  website,
  locale: initialLanguage,
  messages: initialMessages,
  children,
  ...intlProps
}: PropsWithChildren<LanguageProviderType>) {
  const savedLanguage = getSavedInterfaceLanguage();
  const userLanguage = useAppSelector(state => state.utils.me?.profile?.language);
  const localeFromWebsite = useMemo(() => getDefaultLocaleFromWebsite(website), [website]);
  const browserLocale = useMemo(() => getLocaleFromBrowser(), []);
  const websiteLanguages = useMemo(() => website.graphdebate?.languages || [], [website]);

  const defaultLanguage = useMemo(
    () =>
      savedLanguage ||
      userLanguage ||
      browserLocale ||
      localeFromWebsite ||
      initialLanguage ||
      'fr',
    [savedLanguage, userLanguage, browserLocale, localeFromWebsite, initialLanguage],
  );

  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);
  const messagesRef = useRef(initialMessages);

  const handleLanguageChange = useCallback(
    (newLanguage: string) => {
      if (newLanguage && newLanguage !== currentLanguage) {
        setCurrentLanguage(newLanguage);
        saveInterfaceLanguage(newLanguage);
      }
    },
    [currentLanguage],
  );

  /**
   * Update messages when language changes
   */
  useEffect(() => {
    const loadMessages = async () => {
      const newMessages = await fetchMessagesClient(currentLanguage, websiteLanguages as string[]);
      if (newMessages) {
        messagesRef.current = newMessages;
      }
    };

    loadMessages();
  }, [currentLanguage, websiteLanguages]);

  /**
   * Watch for user language change in Redux state
   */
  useEffect(() => {
    userLanguage && handleLanguageChange(userLanguage);
  }, [handleLanguageChange, userLanguage]);

  const contextValue = useMemo(
    () => ({
      currentLanguage,
      setCurrentLanguage: handleLanguageChange,
    }),
    [currentLanguage, handleLanguageChange],
  );

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider
        key={`${initialLanguage}_${currentLanguage}`}
        locale={initialLanguage}
        messages={messagesRef.current}
        {...intlProps}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
});
