import { FormattedMessage } from 'react-intl';

import { moderationMessages } from '../../../i18n/moderation';

export type ModerationRemovalStatusProps = {
  contentType: 'topic' | 'message';
  moderationMotifId: string | null;
  shouldDisplayFullStatus?: boolean;
};

export const ModerationRemovalStatus = ({
  contentType,
  moderationMotifId,
  shouldDisplayFullStatus = true,
}: ModerationRemovalStatusProps) => {
  if (!shouldDisplayFullStatus) return <FormattedMessage {...moderationMessages.default.deleted} />;

  const messageToFormat = moderationMessages[contentType];
  const messageKeyToHandle = moderationMotifId === null ? 'deletedByUser' : 'deletedByModerator';

  return <FormattedMessage {...messageToFormat[messageKeyToHandle]} />;
};
