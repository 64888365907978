import type { filterMessages } from '../../../front/src/thread/Topic/Filter/constants';

export const gdRoutes = {
  home: '/',
  write: '/write',
  profile: '/p/[pseudo]',
  search: '/search',
  message: '/t/[slug]/d/[messageId]',
  topics: '/topics/[filter]',
  topic: '/t/[slug]', // Used in middleware-utils.ts to detect if we're on the topic page.
  categories: '/tags',
  category: '/[tag]',
  groups: '/groups',
  group: '/group/[slug]',
} as const;

export type RouteParams = {
  home: undefined;
  write: undefined;
  categories: undefined;
  category: { tag: string };
  profile: { pseudo: string; tab: 'pages' | 'rewards' | 'comments' | 'friends' | 'preferences' };
  search: { term: string };
  topic: { slug: string };
  message: { slug: string; messageId: string };
  topics: { filter: keyof typeof filterMessages };
};

export type GdPageType = keyof typeof gdRoutes;
export type GdRoutePathname = (typeof gdRoutes)[GdPageType];

export function getPageType(path: GdRoutePathname): GdPageType | undefined {
  return (Object.keys(gdRoutes) as GdPageType[]).find(key => gdRoutes[key] === path);
}
