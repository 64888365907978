import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { isBrowser, isMobile } from '../../utils/web';

type TooltipProps = PropsWithChildren<Omit<TippyProps, 'children'> & { touchTimeout?: number }>;

// Note: tippy.js and popper may be unmaintained. Suggestion if a replacement is required: https://floating-ui.com
export const Tooltip = memo((props: TooltipProps) => {
  const {
    children,
    trigger = 'mouseenter',
    theme = 'material',
    maxWidth = '100%',
    touch = false,
    ...others
  } = props;

  if (!isBrowser /* || isTouchableDevice() */) {
    return <>{children}</>;
  }

  const isMobileDevice = isMobile();

  return (
    // preserve old implementation for continuous delivery please refactor asap
    <Tippy
      {...others}
      maxWidth={maxWidth}
      className={classNames('tippy-align-center', 'tippy-small', props.className)}
      trigger={trigger}
      delay={100}
      theme={theme}
      touch={touch}
      {...(props.touchTimeout &&
        isMobileDevice && {
          onShow: instance => {
            setTimeout(() => instance.hide(), props.touchTimeout);
          },
        })}
    >
      {children as any}
    </Tippy>
  );
});
