import type { MessageDescriptor } from 'react-intl';
import { defineMessages } from 'react-intl';

import { fillTimes, ONE_DAY, ONE_HOUR, ONE_WEEK } from '../utils/date';

export type OptionMessage = {
  value: string | null;
  title?: MessageDescriptor;
  description?: MessageDescriptor | string;
  warning?: MessageDescriptor;
  disabled?: boolean;
};

export const reasonMessages = defineMessages({
  '0': { defaultMessage: 'Non précisé', id: 'mqEidc' },
  '1': { defaultMessage: 'Diffamatoire', id: 'ZMdx7V' },
  '2': { defaultMessage: 'Agression', id: 'JE3vSe' },
  '3': { defaultMessage: 'Insulte', id: 'wRaeq8' },
  '4': { defaultMessage: 'Racisme', id: 'nj56fj' },
  '5': { defaultMessage: 'Illégal', id: 'K2xQHn' },
  '6': { defaultMessage: 'Pseudo vulgaire', id: 'qTJVwy' },
  '7': { defaultMessage: 'Vie privée', id: 's+afR1' },
  '8': { defaultMessage: 'SPAM', id: 'MROph7' },
  '9': { defaultMessage: 'Publicité', id: '3Xp58p' },
  '10': { defaultMessage: 'Pédophilie', id: '+MN2H1' },
  '11': { defaultMessage: 'Pornographie', id: 'tHi49p' },
  '12': { defaultMessage: 'Blagues', id: 'SbR0pu' },
  '13': { defaultMessage: 'Suicide', id: 'gj8mBa' },
  '14': { defaultMessage: 'Illisible', id: 'ncCSk+' },
  '15': { defaultMessage: 'Prostitution', id: '8s30BR' },
  '16': { defaultMessage: 'Respect des victimes', id: 'RQpu0g' },
  '17': { defaultMessage: 'Prosélytisme religieux', id: '9feoCc' },
  '18': { defaultMessage: 'Injure sur religion', id: 'wGFYrA' },
  '19': { defaultMessage: 'Copyright', id: 'Kvo3A0' },
  '20': { defaultMessage: 'Doublon', id: 'ODR+rM' },
  '21': { defaultMessage: 'Lien vers un site web prohibé', id: 'Jn1z+W' },
  '22': { defaultMessage: 'Incitation à la violence/crime/vol', id: 'bujMzU' },
  '23': { defaultMessage: 'Homophobie', id: 'tuAEXV' },
  '24': { defaultMessage: 'Vulgarité', id: 'KBuw6W' },
  '99': { defaultMessage: 'Autre raison', id: 'tP0a3T' },
});

export const REASONS_OPTIONS = Object.entries(reasonMessages).map(([value, label]) => ({
  value,
  label,
}));

export const intl = defineMessages({
  banTitle: { defaultMessage: 'Bannissement', id: '2DIORY' },
  userProfile: { defaultMessage: 'Profil utilisateur', id: 'OFeLnM' },
  moreBanRules: { defaultMessage: 'Règles de bannissement', id: 'jAC5EV' },
  save: { defaultMessage: 'Enregistrer', id: '8pmblh' },
  reasonDesc: { defaultMessage: 'Description du motif :', id: '9sL/OP' },
  noIp: { defaultMessage: 'Aucune adresse IP associée à cet utilisateur', id: '2NHmhP' },
});

export const intlErrors = defineMessages({
  type: { defaultMessage: 'Veuillez sélectionner au moins une règle de banissement', id: 'DjEeeC' },
  reason: {
    defaultMessage: 'Veuillez indiquer le motif et la raison du bannissement',
    id: 'qdY6Db',
  },
  noUser: { defaultMessage: 'Aucun utilisateur sélectionné', id: '8hHvTg' },
  duration: { defaultMessage: 'Réservé aux comptes Premium', id: 'XR4gEe' },
});

const intlModes = defineMessages({
  permanent: { defaultMessage: 'Bannissement permanent', id: 'lkEIdK' },
  temporary: { defaultMessage: 'Bannissement temporaire', id: 'NA15kW' },
  permanentDescription: {
    defaultMessage:
      'Les utilisateurs en bannissement permanent ne pourront plus poster, voter sur les commentaires de votre site.',
    id: '5gC2Xs',
  },
  temporaryDescription: {
    defaultMessage:
      'Les utilisateurs en bannissement temporaire ne pourront plus poster, voter sur les commentaires de votre site durant un lapse de temps de votre choix. Ils recevront une notification de leur suspension et de sa durée.',
    id: 'MjVobo',
  },
  temporaryDisabled: { defaultMessage: 'Fonctionnalité Premium', id: 'eGNeeZ' },
});
export const BAN_MODES = [
  { value: 'permanent', title: intlModes.permanent, description: intlModes.permanentDescription },
  {
    value: 'temporary',
    title: intlModes.temporary,
    description: intlModes.temporaryDescription,
    warning: intlModes.temporaryDisabled,
  },
] as const;

export type BanModeKey = (typeof BAN_MODES)[number]['value'] | '';

const intlOptions = defineMessages({
  shadow: { defaultMessage: 'Bannissement fantôme', id: 'pq9gF7' },
  noBanMessage: { defaultMessage: "Envoyer un message à l'utilisateur", id: 'Ktz55y' },
  shadowDescription: {
    defaultMessage:
      "Les utilisateurs en bannissement fantôme ne sauront pas qu'ils sont bannis. Ils pourront toujours poster, voter sur les commentaires de votre site mais tous les nouveaux commentaires qu'ils posteront ne seront plus visibles à quiconque à l'exception d'eux-mêmes et des modérateurs.",
    id: 'Yl++7J',
  },
  noBanMessageDescription: {
    defaultMessage:
      "Un message est envoyé à l'utilisateur pour l'informer de son bannissement. Une fois ce message envoyé, tous ses commentaires seront automatiquement supprimés.",
    id: '+LZU9j',
  },
  shadowDisabled: {
    defaultMessage:
      "L'envoi d'un message à l'utilisateur n'est pas compatible avec le bannissement fantôme",
    id: 'PtS6Uv',
  },
  noBanMessageDisabled: {
    defaultMessage:
      "Le bannissement fantôme désactive automatiquement l'envoi de message à l'utilisateur",
    id: 'Rei5GK',
  },
  noOptionSelected: {
    defaultMessage:
      "Aucun message ne sera envoyé à l'utilisateur et ses commentaires seront automatiquement supprimés.",
    id: 'lVRj0y',
  },
});
export const BAN_OPTIONS = [
  {
    value: 'noBanMessage',
    title: intlOptions.noBanMessage,
    description: intlOptions.noBanMessageDescription,
    warning: intlOptions.noBanMessageDisabled,
  },
  {
    value: 'shadow',
    title: intlOptions.shadow,
    description: intlOptions.shadowDescription,
    warning: intlOptions.shadowDisabled,
  },
  {
    value: null,
    description: intlOptions.noOptionSelected,
  },
] as const;

export type BanOptionKey = (typeof BAN_OPTIONS)[number]['value'] | '';

const intlDateRanges = defineMessages({
  permanently: { defaultMessage: 'En permanence', id: '7tjLRN' },
  days: {
    defaultMessage: '{length, plural, zero {En permanence} one {jour} other {jours}} ',
    id: 'DNIoxM',
  },
  weeks: {
    defaultMessage: '{length, plural, zero {semaine} one {semaine} other {semaines}}',
    id: 'G3+AOQ',
  },
  hours: {
    defaultMessage: '{length, plural, zero {heure} one {heure} other {heures}}',
    id: '/OUCFw',
  },
  months: { defaultMessage: '{length, plural, zero {mois} one {mois} other {mois}}', id: 'QivGP2' },
});

/* { unit: number; length: number; } | { length: number; base: number; } */
export interface BanDateRange {
  unit?: number;
  label?: MessageDescriptor;
  length: number;
  custom?: boolean;
  base?: number;
}

export const BAN_DATERANGES = [
  { unit: 0, label: intlDateRanges.permanently, length: 0, custom: true },
  ...fillTimes<MessageDescriptor>(23, { unit: ONE_HOUR, label: intlDateRanges.hours }),
  { unit: ONE_DAY, label: intlDateRanges.days, length: 1, custom: true },
  ...fillTimes<MessageDescriptor>(29, { unit: ONE_DAY, label: intlDateRanges.days, length: 2 }),
  ...fillTimes<MessageDescriptor>(2, { unit: ONE_WEEK, label: intlDateRanges.weeks, custom: true }),
  ...fillTimes<MessageDescriptor>(2, {
    unit: ONE_WEEK,
    label: intlDateRanges.weeks,
    length: 3,
  }),
  ...fillTimes<MessageDescriptor>(12, {
    unit: ONE_DAY * 30,
    label: intlDateRanges.months,
  }),
] as const;

export const banMessages = defineMessages({
  email: { defaultMessage: 'Email', id: 'sy+pv5' },
  emailColon: { defaultMessage: 'Email :', id: '8hLqoV' },
  pseudo: { defaultMessage: 'Pseudo', id: 'JGzxax' },
  pseudoColon: { defaultMessage: 'Pseudo :', id: 'pynQlt' },
  ip: { defaultMessage: 'Adresse IP', id: 'aEkDoc' },
  ipColon: { defaultMessage: 'Adresse IP :', id: '/+7S8v' },
  account: { defaultMessage: 'Compte', id: 'SjQZxS' },
  accountColon: { defaultMessage: 'Compte :', id: '5APPWi' },
  blockIPWarning: {
    defaultMessage:
      "Bloquer l'adresse IP d’un utilisateur risque de bloquer involontairement l'adresse IP d’un autre utilisateur qui partagerait la même IP.",
    id: '8J6VV4',
  },
});
export const BAN_TYPES = [
  { value: 'email', title: banMessages.emailColon },
  { value: 'pseudo', title: banMessages.pseudoColon },
  { value: 'ip', title: banMessages.ipColon, warning: banMessages.blockIPWarning },
] as const;

export const defaultBanType = {
  value: 'account',
  title: banMessages.accountColon,
} as const;

type ApiLegacyPseudoBanType = 'username';

export type BanTypeKey =
  | (typeof BAN_TYPES)[number]['value']
  | (typeof defaultBanType)['value']
  | ApiLegacyPseudoBanType;

export const intlReasons = defineMessages({
  title: { defaultMessage: 'Raison du bannissement :', id: 'hdn7fH' },
  reason: { defaultMessage: 'Motif :', id: 'iZhOJp' },
  reasonDesc: { defaultMessage: 'Description du motif :', id: '9sL/OP' },
});
